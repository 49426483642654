<template>
  <div class="h-full min-h-full px-4 pt-16 bg-white w-7/8 lg:px-16">
    <div class="flex justify-between">
      <h1 class="text-4xl font-normal font-poppins">Role Management</h1>
      <Button variant="empty" class="-ml-64"
        ><lock-icon class="inline-block w-4 h-4 mr-2 text-gray-500" />
        System Administrator
      </Button>
    </div>
    <p class="w-1/2 mt-6 font-poppins">
      Manage permissions for each user role in your system. Manage which actions
      each user type can perform and what they are able to view.
    </p>
    <section class="my-16">
      <h2 class="text-2xl font-bold font-poppins">
        System Permissions by User Role
      </h2>
      <p class="w-1/2 mt-2 font-poppins">
        Manage which users have access to make system changes.
      </p>
      <div class="my-8 text-black divide-y-2">
        <div class="grid grid-cols-8 gap-2 py-4 justify-items-center">
          <p
            class="col-span-2 font-bold text-blue-600 font-poppins justify-self-start"
          >
            Permission Type
          </p>
          <p
            v-for="(item, index) in roles"
            :key="index"
            class="font-bold text-center text-blue-600 font-poppins"
          >
            {{ item.title }}
          </p>
        </div>
        <div class="grid grid-cols-8 gap-2 py-4 justify-items-center">
          <p class="col-span-2 text-gray-700 font-poppins justify-self-start">
            Has access to edit presets
          </p>
          <Checkbox
            v-for="(item, index) in getPermissions('presets', 'edit')"
            :key="index"
            :checked="item.active"
            :modelValue="item"
            @update="updatePermission($event)"
            :disabled="item.roleId === 1"
          />
        </div>
        <div class="grid grid-cols-8 gap-2 py-4 justify-items-center">
          <p class="col-span-2 text-gray-700 font-poppins justify-self-start">
            Has access to manager users
          </p>
          <Checkbox
            v-for="(item, index) in getPermissions('users', '*')"
            :key="index"
            :checked="item.active"
            :modelValue="item"
            @update="updatePermission($event)"
            :disabled="item.roleId === 1"
          />
        </div>
        <div class="grid grid-cols-8 gap-2 py-4 justify-items-center">
          <p class="col-span-2 text-gray-700 font-poppins justify-self-start">
            Has ability to create & edit invoices
          </p>
          <Checkbox
            v-for="(item, index) in getPermissions('invoices', 'create|edit')"
            :key="index"
            :checked="item.active"
            :modelValue="item"
            @update="updatePermission($event)"
            :disabled="item.roleId === 1"
          />
        </div>
        <div class="grid grid-cols-8 gap-2 py-4 justify-items-center">
          <p class="col-span-2 text-gray-700 font-poppins justify-self-start">
            Has ability to approve an invoice
          </p>
          <Checkbox
            v-for="(item, index) in getPermissions('invoices', 'approve')"
            :key="index"
            :checked="item.active"
            :modelValue="item"
            @update="updatePermission($event)"
            :disabled="item.roleId === 1"
          />
        </div>
        <div class="grid grid-cols-8 gap-2 py-4 justify-items-center">
          <p class="col-span-2 text-gray-700 font-poppins justify-self-start">
            Has ability to send an invoice
          </p>
          <Checkbox
            v-for="(item, index) in getPermissions('invoices', 'send')"
            :key="index"
            :checked="item.active"
            :modelValue="item"
            @update="updatePermission($event)"
            :disabled="item.roleId === 1"
          />
        </div>
        <div />
      </div>
    </section>
    <Button
      variant="primary"
      class="w-64 mb-12 font-bold font-poppins"
      @click="savePermissions"
      >Save Changes</Button
    >
    <section class="my-16">
      <h2 class="text-2xl font-bold font-poppins">
        Notification Settings by User Role
      </h2>
      <p class="w-1/2 mt-2 font-poppins">
        Manage which users are notified of which changes.
      </p>
      <div class="my-8 text-black divide-y-2">
        <div class="grid grid-cols-8 gap-2 py-4 justify-items-center">
          <p
            class="col-span-2 font-bold text-blue-600 font-poppins justify-self-start"
          >
            Notification Type
          </p>
          <p
            v-for="(role, index) in roles"
            :key="index"
            class="font-bold text-center text-blue-600 font-poppins"
          >
            {{ role.title }}
          </p>
        </div>
        <div class="grid grid-cols-8 gap-2 py-4 justify-items-center">
          <p class="col-span-2 text-gray-700 font-poppins justify-self-start">
            Notified when an invoice is set to
            <span class="font-bold">"Awaiting AP Approval"</span>
          </p>
          <Checkbox
            v-for="(item, index) in getNotifications(
              'awaiting_accounting_approval'
            )"
            :key="index"
            :checked="item.active"
            :modelValue="item"
            @update="updateNotification($event)"
          />
        </div>
        <div class="grid grid-cols-8 gap-2 py-4 justify-items-center">
          <p class="col-span-2 text-gray-700 font-poppins justify-self-start">
            Notified when an invoice is set to
            <span class="font-bold">"Awaiting CSM Approval"</span>
          </p>
          <Checkbox
            v-for="(item, index) in getNotifications(
              'awaiting_director_approval'
            )"
            :key="index"
            :checked="item.active"
            :modelValue="item"
            @update="updateNotification($event)"
          />
        </div>
        <div class="grid grid-cols-8 gap-2 py-4 justify-items-center">
          <p class="col-span-2 text-gray-700 font-poppins justify-self-start">
            Notified when an invoice is set to
            <span class="font-bold">"Awaiting Revision"</span>
          </p>
          <Checkbox
            v-for="(item, index) in getNotifications('needs_revision')"
            :key="index"
            :checked="item.active"
            :modelValue="item"
            @update="updateNotification($event)"
          />
        </div>
        <div class="grid grid-cols-8 gap-2 py-4 justify-items-center">
          <p class="col-span-2 text-gray-700 font-poppins justify-self-start">
            Notified when an invoice is set to
            <span class="font-bold">"Approved"</span>
          </p>
          <Checkbox
            v-for="(item, index) in getNotifications('approved')"
            :key="index"
            :checked="item.active"
            :modelValue="item"
            @update="updateNotification($event)"
          />
        </div>
        <div class="grid grid-cols-8 gap-2 py-4 justify-items-center">
          <p class="col-span-2 text-gray-700 font-poppins justify-self-start">
            Notified when an invoice is marked as
            <span class="font-bold">"Issued to Client"</span>
          </p>
          <Checkbox
            v-for="(item, index) in getNotifications('sent_to_client')"
            :key="index"
            :checked="item.active"
            :modelValue="item"
            @update="updateNotification($event)"
          />
        </div>
        <div />
      </div>
    </section>
    <Button
      variant="primary"
      class="w-64 mb-12 font-bold font-poppins"
      @click="saveNotifications"
      >Save Changes</Button
    >
  </div>
</template>

<script>
import { useStore } from 'vuex';
import { onMounted, ref } from 'vue';
import { useToast } from '@/helpers/composables';

export default {
  setup() {
    const store = useStore();
    let roles = ref([]);
    let permissions = ref([]);
    let notifications = ref([]);
    const toast = useToast();

    onMounted(async () => {
      const response = await Promise.all([
        store.dispatch('get', { action: '/roles' }),
        store.dispatch('get', { action: '/permissions' }),
        store.dispatch('get', { action: '/notifications/settings' }),
      ]);
      roles.value = response[0].data;
      permissions.value = response[1].data;
      notifications.value = response[2].data;
    });

    function getPermissions(resource, action) {
      return permissions.value.filter(
        (r) => r.resource === resource && r.action === action
      );
    }

    function updatePermission({ value, checked }) {
      const index = permissions.value.findIndex((r) => r.id === value.id);
      const item = permissions.value[index];
      item.active = checked;
      permissions.value.splice(index, 1, item);
    }

    function getNotifications(type) {
      return notifications.value.filter((r) => r.type === type);
    }

    function updateNotification({ value, checked }) {
      const index = notifications.value.findIndex((r) => r.id === value.id);
      const item = notifications.value[index];
      item.active = checked;
      notifications.value.splice(index, 1, item);
    }

    async function savePermissions() {
      const { message } = await store.dispatch('post', {
        action: '/permissions',
        formData: { permissions: permissions.value },
      });
      toast.show(message);
    }

    async function saveNotifications() {
      const { message } = await store.dispatch('post', {
        action: '/notifications/settings',
        formData: { notifications: notifications.value },
      });
      toast.show(message);
    }

    return {
      roles,
      permissions,
      getPermissions,
      updatePermission,
      savePermissions,
      saveNotifications,
      getNotifications,
      updateNotification,
    };
  },
};
</script>
